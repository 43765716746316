<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        width="800"
    >
      <v-card :disabled="isLoading" :loading="isLoading">

        <v-card-title>
          Situation des crédit
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8">

          <v-skeleton-loader v-if="isLoading" type="article,article"/>

          <div v-else>
            <v-data-table :expanded.sync="expanded"
                          :headers="headers"
                          :items="salepoints"
                          class="table-border rounded-lg"
                          item-key="id"
                          show-expand
            >
              <template v-slot:item.total_remaining_amount="{ item }">
                <div class="font-weight-medium primary--text">
                  {{ CurrencyFormatting(item.total_remaining_amount) }} DZD
                </div>
              </template>

              <template v-slot:expanded-item="{ headers, item }">

                <td :colspan="headers.length">
                  <v-simple-table class="table-border rounded-lg my-5">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Service</th>
                        <th>Total vendu</th>
                        <th>Comm.service</th>
                        <th>Montant de la comm.</th>
                        <th>Reste à récupérer</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(el,index) in item.services" :key="index">

                        <td>
                          {{ el.name }}
                        </td>

                        <td>
                          {{ CurrencyFormatting(el.total_amount) }}
                        </td>

                        <td class="font-weight-medium gifty--text">
                          {{ el.service_commission }} %
                        </td>

                        <td>
                          {{ CurrencyFormatting(el.commission_amount) }}
                        </td>

                        <td class="font-weight-medium primary--text">
                          {{ CurrencyFormatting(el.remaining_amount) }}
                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                </td>

              </template>
            </v-data-table>

          </div>

        </v-card-text>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      isLoading: false,
      expanded: [],
      salepoints: []
    }
  },
  methods: {
    open(recuperatorId) {
      this.dialog = true
      this.getRecuperatorSalepoints(recuperatorId)
    },
    close() {
      this.dialog = false
    },
    getRecuperatorSalepoints(recuperatorId) {
      this.isLoading = true
      HTTP.get('/v1/recoveries/recuperator/salepoints', {
        params: {
          recuperator_id: recuperatorId
        }
      }).then(res => {
        this.isLoading = false
        this.salepoints = res.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  computed: {
    headers() {
      return [
        {text: 'Nom de pos', value: 'pos_name'},
        {text: 'Type', value: 'type'},
        {text: 'Wilaya', value: 'wilaya.name'},
        {text: 'Commune', value: 'commune.name'},
        {text: 'Reste à récupérer', value: 'total_remaining_amount'},
        {text: '', value: 'data-table-expand'},
      ];
    }
  },
}
</script>

<style scoped>

</style>