<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="300"
    >
      <v-card>
        <v-card-title>
          Télécharger la facteur
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8 text-center">
          <v-btn fab
                 x-large
                 depressed
                 dark
                 color="gifty"
                 @click="printPDF">
            <v-icon dark>mdi-download-outline</v-icon>
          </v-btn>

        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      date: null,
      recuperator_id: null,
    }
  },
  methods: {
    open(item) {
      this.date = item.date
      this.recuperator_id = item.recuperator_id
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    printPDF() {
      let paymentId = this.payment_id
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.user.access_token
      let userId = this.$store.state.user.user.id
      let DownloadURL = baseUrl + '/api/v1/recuperators/payment/export-pdf?token=' + token + '&id=' + userId + '&recuperator_id=' + this.recuperator_id + '&date=' + this.date;
      window.open(DownloadURL, '_blanc')
    }
  }
}
</script>

<style scoped>

</style>