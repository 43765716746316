<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="850"
        scrollable
    >

      <v-card>

        <v-card-title>
          Raison & Map & photo
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8 text-center">

          <v-text-field label="Rasion" disabled v-model="item.raison"/>

          <v-row no-gutters>
            <v-col cols="12" lg="6">
              <v-img height="350"
                     class="pointer"
                     v-if="item.image"
                     :src="fileUrl + item.image"
                     @click="[image = [{src: fileUrl + item.image}],visible = true]"/>
              <v-img v-else height="350"
                     :src="require('@/assets/no-image.png')"/>
            </v-col>
            <v-col cols="12" lg="6">
              <GoogleMaps height="350px" :position="item"/>
            </v-col>
          </v-row>

        </v-card-text>

      </v-card>


    </v-dialog>

    <vue-easy-lightbox escDisabled
                       moveDisabled
                       :visible="visible"
                       :imgs="image"
                       :index="index"
                       @hide="visible = false"></vue-easy-lightbox>

  </div>
</template>

<script>

import GoogleMaps from "@/components/maps/googleMaps";

export default {
  components: {GoogleMaps},
  data() {
    return {
      fileUrl: process.env.VUE_APP_FILE_URL,
      dialog: false,
      item: {},

      //lightbox
      image: '',
      visible: false,
      index: 0
    }
  },
  methods: {
    open(item) {
      this.item = {
        lat: parseFloat(item.latitude),
        lng: parseFloat(item.longitude),
        image: item.image,
        raison: item.recovery_raison ? item.recovery_raison.name : '',
      }
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
  }
}
</script>

