<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        width="700"
    >
      <v-card :disabled="isLoading" :loading="isLoading">

        <v-card-title>
          Ajouter un recouvrement
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8">

          <v-row>
            <v-col>
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  max-width="290px"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="form.recovery_at"
                      :error-messages="errors.recovery_at"
                      label="Date de recouvrement *"
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.recovery_at"
                               locale="fr"
                               @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  max-width="290px"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="form.recovery_at_hour"
                      :error-messages="errors.recovery_at_hour"
                      label="Heure de recouvrement *"
                      prepend-inner-icon="mdi-clock"
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-model="form.recovery_at_hour"
                    @input="menu2 = false"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-autocomplete v-model="form.account_id"
                          :disabled="salepointsLoading"
                          :error-messages="errors.account_id"
                          :items="salepoints"
                          :loading="salepointsLoading"
                          item-text="pos_name"
                          item-value="account_id"
                          label="Sélectionnez un POS *"/>

          <v-select v-model="form.service_id"
                    :disabled="servicesLoading"
                    :error-messages="errors.service_id"
                    :items="services"
                    :loading="servicesLoading"
                    item-text="name"
                    item-value="id"
                    label="Sélectionnez un Service *"/>

          <v-alert v-if="form.account_id"
                   :type="remainingAmount > 0 ? 'success' : 'warning'"
                   border="left"
                   dense
                   icon="mdi-cash"
                   prominent
                   text>

                <span class="d-block pb-2">
                   <span class="font-weight-medium">Montant à récupérer  :</span>
                    {{ remainingAmount }} DZD
                </span>

          </v-alert>

          <v-text-field v-model="form.amount"
                        :error-messages="errors.amount"
                        label="Montant *"
                        suffix="DA"/>

          <v-select v-model="form.recovery_raison_id"
                    :disabled="raisonsLoading"
                    :error-messages="errors.recovery_raison_id"
                    :items="raisons"
                    :loading="raisonsLoading"
                    item-text="name"
                    item-value="id"
                    label="Raison"/>

          <v-file-input :error-messages="errors.image"
                        accept="image/*"
                        label="Image *"
                        prepend-icon=""
                        prepend-inner-icon="mdi-paperclip"
                        @change="changeUpload"/>


          <v-row>
            <v-col>
              <v-text-field v-model="form.longitude"
                            :error-messages="errors.longitude"
                            label="Longitude *"/>
            </v-col>
            <v-col>
              <v-text-field v-model="form.latitude"
                            :error-messages="errors.latitude"
                            label="Latitude *"/>
            </v-col>
          </v-row>


        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn :disabled="!form.account_id || remainingAmount <= 0"
                 :loading="isLoading"
                 color="primary"
                 depressed
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Sauvgarder
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import moment from "moment";

export default {
  data() {
    return {
      isLoading: false,
      menu: false,
      menu2: false,
      dialog: false,

      raisonsLoading: false,
      raisons: [],

      servicesLoading: false,
      services: [],

      salepointsLoading: false,
      salepoints: [],

      form: {
        account_id: null,
        recovery_raison_id: null,
        service_id: null,
        amount: null,
        recovery_at: moment().format('YYYY-MM-DD'),
        recovery_at_hour: moment().format('H:m'),
        longitude: null,
        latitude: null,
        image: '',
        source: 'web',
        recuperator_id: null,
      },

      errors: {}
    }
  },
  computed: {
    remainingAmount() {
      if (this.form.account_id && this.form.service_id && this.salepoints) {
        const salepoint = this.salepoints.find(el => el.account_id === this.form.account_id);
        if (salepoint && salepoint.services && salepoint.services.length) {
          const service = salepoint.services.find(el => el.id === this.form.service_id);
          return service ? service.remaining_amount : 0;
        }
      }
      return 0;
    },
  },
  methods: {
    open(recuperatorId) {
      this.errors = {}
      this.resetForm()
      this.dialog = true
      this.form.recuperator_id = recuperatorId
      this.getRecuperatorSalepoints()
      this.getRecoveries()
      this.getServices()
    },
    close() {
      this.dialog = false
    },
    changeUpload(file) {
      this.getBase64(file).then(res => {
        const str = res
        const regex = /data:.*base64,/
        this.form.image = str.replace(regex, "")
      })
    },
    save() {
      this.errors = {}
      this.isLoading = true
      HTTP.post('/v1/recoveries/store', this.form).then(() => {
        this.isLoading = false
        this.$successMessage = 'Le recouvrement a été ajouté avec succès.'
        this.close()
        this.$emit('refresh')
      }).catch(err => {
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur s'est produite veuillez corriger."
        this.isLoading = false
        console.log(err)
      })
    },

    getRecuperatorSalepoints() {
      this.salepointsLoading = true
      HTTP.get('/v1/recoveries/recuperator/salepoints', {
        params: {
          recuperator_id: this.form.recuperator_id
        }
      }).then(res => {
        this.salepointsLoading = false
        this.salepoints = res.data.data
      }).catch(err => {
        this.salepointsLoading = false
        console.log(err)
      })
    },
    getRecoveries() {
      this.raisonsLoading = true
      HTTP.get('/v1/recovery-raisons').then(res => {
        this.raisonsLoading = false
        this.raisons = res.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    getServices() {
      this.servicesLoading = true
      HTTP.get("/v1/recoveries/recuperator/services", {
        params: {
          recuperator_id: this.form.recuperator_id
        }
      }).then(res => {
        this.servicesLoading = false
        this.services = res.data.data
      }).catch(err => {
        this.servicesLoading = false
        console.log(err)
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    resetForm() {
      this.form = {
        account_id: null,
        service_id: null,
        recovery_raison_id: null,
        amount: null,
        recovery_at: moment().format('YYYY-MM-DD'),
        recovery_at_hour: moment(moment.now()).format('H:m'),
        longitude: null,
        latitude: null,
        image: '',
        source: 'web',
        recuperator_id: null,
      }
    }
  }
}
</script>

<style scoped>

</style>