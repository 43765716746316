<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        width="500"
    >
      <v-card :disabled="isLoading" :loading="isLoading">

        <v-card-title>
          Ajouter un paiement
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8">

          <v-skeleton-loader v-if="isLoading" type="article,article,article"/>

          <div v-else>

            <v-alert :type="totalSalepoints === 0 ? 'warning':'success'" prominent text border="left">

            <span class="d-block pb-2">
              <span class="font-weight-medium">Récupérateur :</span>
              {{ recuperatorName }}
            </span>

              <span class="d-block">
              <span class="font-weight-medium">Date de recouvrement :</span>
              {{ date }}
            </span>

              <span class="d-block py-2">
               <span class="font-weight-medium">Total des opérations : </span>
              {{ totalOperations }}
            </span>

              <span class="d-block pb-2">
               <span class="font-weight-medium">Total des POS visités : </span>
              {{ totalSalepoints }}
            </span>

              <span class="d-block">
               <span class="font-weight-medium">Montant total :</span>
              {{ CurrencyFormatting(realAmount) }} DA
            </span>

            </v-alert>

            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="form.date"
                    label="Date *"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    :error-messages="errors.date"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  @change="getPaymentDetails"
                  v-model="form.date"
                  @input="menu = false"
              ></v-date-picker>
            </v-menu>

            <v-simple-table class="grey lighten-4 mb-5 mt-3">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Billet d'argent
                  </th>
                  <th class="text-left" width="200">
                    Quantité
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,i) in money" :key="i">
                  <td>
                    <v-icon color="gifty" class="mr-2 mt--5">mdi-cash</v-icon>
                    <span class="font-weight-medium gifty--text">{{ item.value }} DZD</span>
                  </td>
                  <td>
                    <v-text-field outlined
                                  v-number
                                  min="0"
                                  dense
                                  clearable
                                  @input="total"
                                  v-model="item.quantity"
                                  hide-details
                                  suffix="Qte"
                                  type="number"/>
                  </td>
                </tr>

                <tr>
                  <td>
                  <span class="font-weight-medium gifty--text">
                    MONTANT TOTAL :
                  </span>
                  </td>
                  <td>
                    <v-text-field disabled suffix="DZD"
                                  v-model="form.paid_amount"/>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-alert type="error"
                     dismissible
                     text
                     dense
                     v-if="errors && errors.paid_amount">
              {{ errors.paid_amount[0] }}
            </v-alert>

            <v-alert type="error"
                     dismissible
                     text
                     dense
                     v-if="errors && errors.msg">
              {{ errors.msg }}
            </v-alert>


            <v-textarea rows="2"
                        label="Remarque"
                        outlined
                        placeholder="Écrire une remarque ici ..."
                        :error-messages="errors.remark"
                        prepend-inner-icon="mdi-pencil-box-outline"
                        v-model="form.remark"/>

          </div>

        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary"
                 @click="save"
                 :loading="btnLoading"
                 :disabled="totalSalepoints === 0"
                 depressed>
            <v-icon left>mdi-content-save</v-icon>
            Sauvgarder
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <DownloadInvoiceDialog ref="downloadInvoiceDialog"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import DownloadInvoiceDialog from "@/views/recoveries/components/DownloadInvoiceDialog";

export default {
  components: {DownloadInvoiceDialog},
  data() {
    return {
      date: null,
      totalSalepoints: 0,
      totalOperations: 0,
      realAmount: 0,
      recuperatorName: null,
      money: [],

      btnLoading: false,
      menu: false,
      dialog: false,
      isLoading: false,
      form: {
        remark: null,
        recuperator_id: null,
        paid_amount: 0,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      errors: {}
    }
  },
  methods: {
    open(recuperatorId) {
      this.errors = {}
      this.dialog = true
      this.form.recuperator_id = recuperatorId
      this.form.paid_amount = 0
      this.getPaymentDetails()
    },
    close() {
      this.dialog = false
    },
    getPaymentDetails() {
      this.errors = {}
      this.isLoading = true
      HTTP.get('/v1/recoveries/recuperators/payment-details', {
        params: {
          recuperator_id: this.form.recuperator_id,
          date: this.form.date,
        }
      }).then(res => {
        this.isLoading = false
        this.date = res.data.data.date
        this.totalSalepoints = res.data.data.totalSalepoints
        this.totalOperations = res.data.data.totalOperations
        this.recuperatorName = res.data.data.recuperatorName
        this.realAmount = res.data.data.totalAmount
        this.money = res.data.data.money.map(el => {
          return {
            id: el.id,
            value: el.value,
            quantity: 0,
          }
        })
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    save() {
      this.errors = {}
      this.btnLoading = true

      let data = this.form
      data['money'] = this.money

      HTTP.post('/v1/recoveries/recuperators/payment/store', data).then((res) => {
        this.isLoading = false
        this.btnLoading = false
        this.$successMessage = 'La paiement a été ajouté avec succès.'
        this.close()
        this.$refs.downloadInvoiceDialog.open(res.data.data)
        this.$emit('refresh')
      }).catch(err => {
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur s'est produite veuillez corriger."
        this.btnLoading = false
        console.log(err)
      })
    },
    total() {
      let money = this.money
      this.form.paid_amount = money.reduce((accum, item) => accum + (item.value * item.quantity), 0)
    }
  },

}
</script>

<style scoped>

</style>