<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="400"
        scrollable
    >

      <v-card>

        <v-card-title>
          Filtrer par
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-dialog
              ref="dialog"
              v-model="modal"
              persistent
              width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  @click:clear="dates = []"
                  v-model="dateRangeText"
                  readonly
                  append-icon="mdi-calendar-outline"
                  clearable
                  label="Dates..."
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker v-model="dates" scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Fermer
              </v-btn>
              <v-btn text
                     color="primary"
                     :disabled="dates.length < 2"
                     @click="modal = false">
                ok
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-select
              :items="statuses"
              item-value="value"
              item-text="name"
              v-model="status"
              label="Statut..."
              clearable
          ></v-select>

          <v-select
              :loading="isLoading"
              :disabled="isLoading"
              multiple
              chips
              small-chips
              deletable-chips
              :items="raisons"
              item-value="id"
              item-text="name"
              v-model="raisonIds"
              label="Raisons..."
              clearable
          ></v-select>

        </v-card-text>


        <v-divider/>

        <v-card-actions>
          <v-btn color="primary"
                 @click="handleFilter"
                 :disabled="dates.length < 2 && !status && !raisonIds.length"
                 depressed block
                 type="submit">
            <v-icon left>mdi-magnify</v-icon>
            Rechercher
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: false,
      modal: false,
      dialog: false,
      dates: [],
      raisonIds: [],
      status: null,

      raisons: [],
    }
  },
  methods: {
    open() {
      this.dialog = true
      this.getRaisons()
    },
    close() {
      this.dialog = false
    },
    handleFilter() {
      let data = {
        dates: this.dates,
        raisonIds: this.raisonIds,
        status: this.status
      }
      this.$emit('filter', data)
      this.close()
    },
    getRaisons() {
      this.isLoading = true
      HTTP.get('/v1/recovery-raisons').then(res => {
        this.isLoading = false
        this.raisons = res.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  computed: {
    dateRangeText: {
      get() {
        return this.dates.join(' ~ ')
      },
      set(dates) {
        return dates
      }
    },
    statuses() {
      return [
        {name: 'En attente', value: 'pending'},
        {name: 'Validé', value: 'validated'}
      ]
    }
  },
}
</script>

